
import Vue, { VueConstructor } from "vue"
import ListParagon from "@/components/ListParagon.vue"
import DateFormatMixin from "@/mixins/DateFormatMixin.vue"

export default (Vue as VueConstructor<Vue & InstanceType<typeof DateFormatMixin>>).extend({
  components: {
    ListParagon,
  },
  mixins: [
    DateFormatMixin
  ],
  data () {
    return {
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.space_name"),
          sortable: false,
          value: 'space_name'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.space_id"),
          sortable: false,
          value: 'space_id'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.user_name"),
          sortable: false,
          value: 'user_name'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.activity_logs.user_id"),
          sortable: false,
          value: 'user_id'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.activity_logs.schema"),
          sortable: false,
          value: 'table_name'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.activity_logs.type"),
          sortable: false,
          value: 'type'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.activity_logs.created_at"),
          sortable: false,
          value: 'created_at'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.activity_logs.title"),
          sortable: false,
          value: 'title'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.activity_logs.description"),
          sortable: false,
          value: 'description'
        }
      ],
      filters: [
        {label: 'category', type: 'text-field'},
        {label: 'space_id', type: 'text-field'},
        {label: 'user_id', type: 'text-field'},
        {label: 'created_at', type: 'date', allowsOnlyOneDate: true},
      ] as {label: string, type: string, url: string|null, text: string|null, value: string|null}[],
      url: "/v1/admin/logs"
    }
  }
})
