
  import Vue from 'vue'
  import ActivityLogList from '@/components/admin/ActivityLogList.vue'

  export default Vue.extend({
    name: 'AdminActivityLogs',
    components: {
      ActivityLogList
    }
  })
